<template>
	<div class="page">
		<div class="w1200 spell_page">
			<!-- 音频 -->
			<audio controls ref="commonaudioCom" class="disnone">
				<source src="" />
			</audio>
			<div class="block48"></div>
			<!-- <topHeader></topHeader> -->
			<div class="spell_box_one">
				<div class="titilebox">
					<div class="leftbox">
						<img @click="handleComfirm" class="leftgo" src="../assets/static/left_go.png"/>
						<div class="text1" v-if="type==0 || type==1">
							<span>听音拼写 | 综合训练</span>
						</div>
						<div class="text1" v-if="type==2">
							<span>错词闯关</span>
						</div>
					</div>
					<div class="centerbox">
						<span v-if="!showallanswer">剩余时间：{{timerText}}</span>
						<span v-if="showallanswer">完成时间：{{timerText}}</span>
					</div>
					<div class="leftbox"></div>
					<!-- <div class="titlename_box">
						<div class="itemobj fontsize14" :class="{'active':index==bookdtypeindex}" v-for="(item,index) in bookdtypeList" :key="index">
							{{item}}
						</div>
					</div> -->
				</div>
				<div class="spellconbox">
					<div class="datalist">
						<div class="itemobj" v-for="(item,index) in dataList" :key="index" v-show="item.testStatus!=1">
							<div class="linsten-block">
								<div class="textindex">{{index+1}}.</div>
								<div class="linsten-btn" @click="playAudioCom(item.phAmMp3)">
									<img class="img100" src="../assets/static/linsten.png"/>
								</div>
								<div class="word-desc clamp" v-if="item.showCn&&item.wordTpye.length>0">
								    <span>{{ item.wordTpye[0].part }}</span>
								    <span class="desc2" v-for="(mitem, mindex) in item.wordTpye[0].means"
								    	:key="mindex">{{ mitem }};<span v-html="'&nbsp;'"></span>
								    </span>
								</div>
								<div class="btnshow" v-if="!item.showCn" @click="handleShowCn(index)">显示释义</div>
							</div>
							<div class="wordbc">
								<div class="label1">输入单词：</div>
								<div class="inputlistbox" @click="playAudioCom(item.phAmMp3)">
									<input class="input" placeholder="请输入" :ref="`input${index}`" v-model="wordListValue[index]" @input="handleinput(index)" @keydown.enter="handleinputenter(index)"/>
								</div>
							</div>
							<div class="wordbc1" v-if="showallanswer">
								<div class="text1">正确答案：<span>{{item.word}}</span></div>
							</div>
						</div>
					</div>
					<div class="block30"></div>
					<div v-if="!showallanswer&&testNum==1" class="btn1" @click="handletijiao">提交</div>
					<div v-if="!showallanswer&&testNum==2" class="btn1" @click="handletijiao">再次提交</div>
					<div class="btn_cancel" v-if="showallanswer" @click="showfinish = true">关闭</div>
					<div class="block30"></div>
				</div>
			</div>
			
		</div>
		
		<div class="block30"></div>
		<!-- 完成 -->
		<!-- 完成测试 -->
		<div class="show_tc_box" v-if="showfinish">
			<div class="zezhao10"></div>
			<div class="w1200 finish_box">
				<div class="imgclose" @click="handleComfirm">
					<img class="img100" src="../assets/static/Frame3465600.png"/>
				</div>
				<div class="title1">
					<img v-if="type==2" class="img100" src="../assets/static/cccg_success.png"/>
					<img v-else class="img100" src="../assets/static/spell_finish.png"/>
				</div>
				<div class="desc1">
					<div class="text1">{{score}}</div>
					<div class="text2">你的分数</div>
				</div>
				<div class="conbox">
					<div class="text text1">正确题目：<span>{{duiNum}}</span></div>
					<div class="text text2">错误题目：<span>{{cuoNum}}</span></div>
				</div>
				<div class="btnbox">
					<div class="btn1 btn2" @click="handleshowanswer">查看错误</div>
					<div class="btn1" @click="handleComfirm">Fighting</div>
				</div>
				
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapState,
		mapMutations
	} from "vuex";
	import {showLoading,hideLoading} from '@/common/loading.js';
	import topHeader from "@/components/topHeader/topHeader.vue";
	export default {
		components:{
			topHeader
		},
		data() {
			return {
				type:0,//1是听音读写， 2是从错词来的
				bookdtypeList:["单词速记","词义连连","听音拼写","单元测试"],
				bookdtypeindex:2,//下标
				dataList:[],
				timer:null,
				totalSeconds: 900,
				useSeconds: 0,
				timerText:"15:00",//时间
				showCn:false, //中文
				wordListValue:[],//新建输入框，解决无法双向绑定问题
				testNum:1,//测试次数
				duiNum:0,//对
				cuoNum:0,//对
				score:0,//分数
				showfinish:false,//完成
				showallanswer:false,//完成
			};
		},
		mounted: function() {
			var _this = this
			if(this.$route.query&&this.$route.query.type){
				this.type = Number(this.$route.query.type)
			}
			if(this.studyingData.successwords&&this.studyingData.successwords.length>0){
				this.wordListValue = []
				var dataList = []
				var successwords = this.shuffleArray(this.studyingData.successwords)
				successwords.forEach((item, index) => {
					try{
						item["wordTpye"] =  JSON.parse(item.definition)
					}catch(e){
						item["wordTpye"] = []
					}
					dataList.push({
						id:item.id,
						word:item.word,
						wordId:item.wordId,
						phAm:item.phAm,
						phAmMp3:item.phAmMp3,
						phEn:item.phEn,
						phEnMp3:item.phEnMp3,
						showCn:false,
						testStatus:0, //1是正确，2是错误
						wordTpye:item.wordTpye,
						bookId:item.bookId || 0,
						lessonId:item.lessonId || 0
					})
					//新建输入框，解决无法双向绑定问题
					_this.wordListValue.push("")
				});
				this.dataList = dataList
				this.duiNum = 0 //对
				this.cuoNum = 0//对
				this.score = 0 //分数
				this.testNum = 1
				// 创建定时器
				this.timer = setInterval(this.updateCountdown, 1000);
			}else{
				this.$confirm('当前暂无可拼写数据，请返回重试！', '温馨提示', {
					confirmButtonText: '确定',
					cancelButtonText: '返回首页',
					type: 'warning'
				}).then(() => {
					_this.sysgoback()
				}).catch(() => {
					_this.$router.replace("/")
				});
				
			}
			
		},
		beforeDestroy() {
		    // 清除定时器
			if(this.timer){
				clearInterval(this.timer);
			}
		},
		computed: {
			...mapState(["userInfo", "isLogin", "myReadingObj",'studyingData']),
		},
		methods: {
			...mapMutations(["SET_Data","SET_USERINFO"]),
			navigato(url,query) {
				this.$util.routerPath(url,query)
			},
			//关闭
			handleclosefun(){
				this.showfinish = false
			},
			//查看答案
			handleshowanswer(){
				this.showallanswer = true
				this.showfinish = false
				this.dataList.forEach((item, index) => {
					item.showCn = true
				});
				//完成试卷时间转换
				let minutes = Math.floor(this.useSeconds / 60);
				let seconds = this.useSeconds % 60;
				minutes = minutes < 10 ? '0' + minutes : minutes;
				seconds = seconds < 10 ? '0' + seconds : seconds;
				this.timerText = minutes + ':' + seconds;
			},
			//完成
			async handleComfirm(){
				if(this.type==1){
					//单元
					this.navigato('studyPage',{type:2})
				}else{
					//错词
					this.sysgoback()
				}
			},
			//提交
			async handletijiao(){
				var _this = this
				//console.log(this.wordListValue)
				_this.$confirm("是否确认提交？", '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async() => {
					var newrecords = []
					showLoading()
					var dataList = _this.dataList
					dataList.forEach((item, index) => {
						if(item.testStatus!=1){
							if(_this.wordListValue[index]){
								const word = item.word.replace(/[^a-zA-Z]/g, "").toLowerCase()
								const keyword = _this.wordListValue[index].replace(/[^a-zA-Z]/g, "").toLowerCase()
								if(keyword==word){
									item["testStatus"] = 1 //1是正确，2是错误
									//删除错词
									if(_this.type==2){
										var params = {
											id:item.id,
											userUuid:_this.userInfo.uuid,
										}
										_this.$common.deluserErrWord(params)
									}
								}else{
									item["showCn"] = true
									item["testStatus"] = 2 //1是正确，2是错误
								}
							}else{
								item["showCn"] = true
								item["testStatus"] = 2 //1是正确，2是错误
							}
						}
					});
					var duiNum = 0
					var cuoNum = 0
					dataList.forEach((item, index) => {
						if(item.testStatus==1){
							duiNum +=1
						}else{
							cuoNum +=1
						}
					});
					console.log(duiNum)
					console.log(cuoNum)
					//第一次全对
					if(duiNum>=dataList.length){
						_this.testNum = 2
					}
					if(_this.testNum==1){
						_this.testNum +=1
						_this.dataList = dataList
						hideLoading()
					}else{
						_this.duiNum  = duiNum
						_this.cuoNum  = cuoNum
						//分数
						_this.score = Number(((_this.duiNum / dataList.length)  * 100).toFixed(0));
						_this.showfinish = true
						if(_this.timer){
							clearInterval(_this.timer);
						}
						if(_this.type==1){
							//完成训练
							var params = {
								recordType:2,//测试类型 0:单词速记 1:单词连连 2:听音拼写 3:单元测试
								bookId:_this.dataList[0].bookId,
								booksLessonId:_this.dataList[0].lessonId,
								userUuid:_this.userInfo.uuid,
							}
							await _this.$common.saveBooksLessonRecord(params)
							hideLoading()
							var studyingData = _this.studyingData
							studyingData.successwords = []
							_this.SET_Data({ key: 'studyingData', value:studyingData})
						}else{
							hideLoading()
						}
					}
				}).catch(() => {});
			},
			//展示中文
			handleShowCn(index){
				this.dataList[index].showCn = true
			},
			//输入监听
			handleinput(index){
				this.wordListValue[0] = this.wordListValue[0].replace(/[^a-zA-Z0-9]/g, '')
			},
			//输入回车监听
			handleinputenter(index){
				if(this.showallanswer){
					return;
				}
				console.log("回车",index)
				var newindex = index + 1
				var dataList = this.dataList
				if(newindex>=dataList.length){
					return;
				}else{
					while (dataList[newindex].testStatus==1) {
						newindex++;
						if(newindex>=dataList.length){
							console.log("while end")
							break;
						}
					}
					if(newindex>=dataList.length){
						return;
					}
					this.playAudioCom(dataList[newindex].phAmMp3)
					const nextInput = this.$refs[`input${newindex}`];
					//console.log(nextInput)
					if (nextInput) {
					    nextInput[0].focus();
					}
				}
			},
			//播放音频
			playAudioCom(src) {
				var _this = this
				try{
					_this.$refs.commonaudioCom.src = src;
					_this.$nextTick(res => {
					    _this.$refs.commonaudioCom.load()
					    _this.$refs.commonaudioCom.play()
					})
				}catch(e){
					//TODO handle the exception
				}
			},
			//下标
			handlebookdtype(type){
				this.bookdtypeindex = type
			},
			// 更新倒计时
			updateCountdown() {
				if (this.totalSeconds > 0) {
				    this.totalSeconds -= 1;
					this.useSeconds +=1
					let minutes = Math.floor(this.totalSeconds / 60);
					let seconds = this.totalSeconds % 60;
					minutes = minutes < 10 ? '0' + minutes : minutes;
					seconds = seconds < 10 ? '0' + seconds : seconds;
					this.timerText = minutes + ':' + seconds;
				} else {
					this.totalSeconds = 900,
					this.timerText = "15:00"//时间
					if(this.timer){
						clearInterval(this.timer);
					}
					//提交，结果，时间到
					this.testNum +=1
					this.handletijiao()
				}
			},
			//打乱顺序
			shuffleArray(array) {
				for (let i = array.length - 1; i > 0; i--) {
				  // 生成[0, i]范围内的随机索引
				  const j = Math.floor(Math.random() * (i + 1));
				  // 交换array[i]和array[j]
				  [array[i], array[j]] = [array[j], array[i]];
				}
				return array;
			},
			
		},
	};
</script>
<style lang="scss" scoped>
	.spell_page{
		position: relative;
		z-index: 2;
	}
	.spell_box_one{
		width: 100%;
		padding: 0 32px;
		box-sizing: border-box;
		position: relative;
		border-radius: 20px;
		border: 4px solid rgba(255, 255, 255, 0.00);
		background: linear-gradient(180deg, #FFF 16.74%, #F2F5FA 60.03%, #E1EAFB 99.98%);
		.titilebox{
			width: 100%;
			height: 92px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-bottom: 1px solid #F0F0F0;
			.leftbox{
				width: 328px;
				display: flex;
				align-items: center;
				.leftgo{
					width: 32px;
					height: 32px;
					cursor: pointer;
				}
				.text1{
					margin-left: 12px;
					color: #222;
					font-size: 24px;
				}
			}
			.centerbox{
				color: #273B90;
				font-size: 20px;
			}
			.titlename_box{
				width: 328px;
				padding: 5px;
				display: flex;
				align-items: center;
				border-radius: 100px;
				border: 1px solid #E5E6EB;
				background-color: #ffffff;
				.itemobj{
					width: 80px;
					height: 28px;
					line-height: 28px;
					text-align: center;
					border-radius: 100px;
					background: #ffffff;
					color: #666;
				}
				.active{
					background: #3773F5;
					color: #ffffff;
				}
			}
		}
		.spellconbox{
			width: 100%;
			height: 600px;
			overflow-y: auto;
			.datalist{
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				.itemobj{
					width: 46%;
					margin-top: 32px;
					.wordbc{
						width: 100%;
						display: flex;
						align-items: center;
						margin-top: 8px;
						.label1{
							width: 100px;
							display: flex;
							align-items: center;
							font-size: 20px;
							color: #000000;
							flex-shrink: 0;
						}
						.inputlistbox{
							flex: 1;
							display: flex;
							align-items: center;
							.input{
								width: 100%;
								height: 42px;
								line-height: 42px;
								color: #000000;
								border-radius: 6px;
								border: 1px solid #0194FF;
								background: #FFFFFF;
								font-size: 16px;
								padding: 0 12px;
								box-sizing: border-box;
							}
						}
					}
					.wordbc1{
						height: 58px;
						width: 100%;
						display: flex;
						align-items: center;
						color: #222;
						font-size: 20px;
						.text1{
							display: flex;
							align-items: center;
							font-size: 20px;
							span{
								color: #F50;
							}
						}
					}
				}
				.linsten-block{
					width: 100%;
					display: flex;
					align-items: center;
					.textindex{
						color: #273B90;
						font-size: 32px;
						flex-shrink: 0;
						margin-right: 8px;
					}
					.linsten-btn{
						width: 48px;
						height: 48px;
						cursor: pointer;
						flex-shrink: 0;
					}
					.text{
						flex: 1;
						margin-left: 8px;
						color: #273B90;
						font-size: 24px;
						text-align: right;
					}
					.btnshow{
						margin-left: auto;
						width: 112px;
						height: 42px;
						color: #FFFFFF;
						line-height: 42px;
						text-align: center;
						border-radius: 12px;
						background: #3773F5;
						font-size: 20px;
						cursor: pointer;
					}
					.word-desc {
						flex: 1;
						margin-left: 8px;
						color: #273B90;
						font-size: 22px;
						.desc2{
							margin-left: 4px;
						}
					}
				}
			}
		}
		.btn1{
			width: 160px;
			margin: 18px auto 0px;
			height: 48px;
			color: #0772FF;
			line-height: 48px;
			text-align: center;
			border-radius: 25px;
			background: linear-gradient(180deg, #FFF 0%, #C5FFFF 100%);
			font-size: 20px;
			cursor: pointer;
		}
		.btn_cancel{
			width: 160px;
			margin: 18px auto 0px;
			height: 48px;
			color: #fff;
			line-height: 48px;
			text-align: center;
			border-radius: 25px;
			background: #999;
			font-size: 20px;
			cursor: pointer;
		}
	}
	.finish_box{
		padding: 58px 28px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		position: relative;
		z-index: 9999;
		border-radius: 40px;
		background: linear-gradient(180deg, #63E0FF 0%, #24A7EF 100%);
		box-shadow: 0px -8px 4px 0px #2FC3EA inset;
		.imgclose{
			width: 40px;
			height: 40px;
			position: absolute;
			right: 32px;
			top: 28px;
			z-index: 2;
			cursor: pointer;
		}
		.title1{
			margin-top: 18px;
			width: 432px;
			height: 63px;
		}
		.desc1{
			margin-top: 28px;
			width: 82%;
			height: 185px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			border-radius: 20px;
			border: 3px solid #50FFFF;
			background: radial-gradient(143.33% 75.16% at 17.95% 93.94%, #80ADFF 0%, #6FD9FE 100%);
			.text1{
				color: #F50;
				font-size: 64px;
				text-align: center;
			}
			.text2{
				color: #FFF;
				text-align: center;
				font-size: 32px;
			}
		}
		.conbox{
			width: 100%;
			margin-top: 48px;
			display: flex;
			align-items: center;
			justify-content: center;
			.text{
				color: #FFF;
				font-size: 28px;
				span{
					color: #273B90;
				}
			}
			.text2{
				margin-left: 28px;
			}
		}
		.btnbox{
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			.btn2{
				margin-right: 28px;
			}
		}
		.btn1{
			margin-top: 48px;
			width: 160px;
			height: 48px;
			color: #0772FF;
			line-height: 48px;
			text-align: center;
			border-radius: 25px;
			background: linear-gradient(180deg, #FFF 0%, #C5FFFF 100%);
			font-size: 20px;
			cursor: pointer;
		}
	}
</style>